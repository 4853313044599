<template>
  <v-container fluid>
    <v-card
      elevation="0"
      class="pa-4"
    >
      <v-card-title
        class="text-subtitle-1 font-weight-bold"
      >
      <v-row no-gutters>
        <v-col cols="10">
          {{ $t("languagesSettings.freightTabName") }}
        </v-col>
        <v-col class="d-flex justify-end">
          <v-btn
            color="primary"
            @click="addNew"
          >
            {{ $t("languagesSettings.addNew") }}
          </v-btn>
        </v-col>
      </v-row>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="tableHeaders"
          :items="items"
          :items-per-page="-1"
          hide-default-footer
        >
          <template v-slot:item={item}>
            <tr>
              <td v-for="lang in languages" :key="lang.nameISO">
                <v-text-field
                  :disabled="item.id != null"
                  v-model="item[lang.nameISO]"
                  outlined
                  dense
                  single-line
                  hide-details
                  @blur="dirtBlur(item)"
                ></v-text-field>
              </td>
              <td class="d-flex justify-end align-center">
                <v-btn
                  v-if="item.id == null"
                  :disabled="isSaveButtonDisabled(item)"
                  class="mr-2"
                  color="primary"
                  depressed
                  @click="saveItem(item)"
                >
                  {{ $t("common.save") }}
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog
      v-if="removeVm"
      v-model="removeDialogActive"
      width="800"
    >
      <v-card>
        <v-card-title>
          {{ $t("languagesSettings.removeTranslation") }}
        </v-card-title>
        <v-card-text>
          {{ $t("languagesSettings.areYouSureYouWantToDeleteTranslation") }} <strong>{{ removeVm['pl-PL'] }}</strong>?
        </v-card-text>
        <v-card-actions class="px-6 pb-4 justify-end">
          <v-btn
            outlined
            @click="closeRemoveDialog"
          >
            {{ $t("languagesSettings.close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Service from '@/services'

export default {
  name: 'FreightTranslationsTab',
  props: {
    languages: {
      type: Array,
      required: true
    }
  },
  computed: {
    tableHeaders () {
      const headers = this.languages.map(lang => {
        const header = {
          text: lang.nameISO,
          value: lang.nameISO
        }
        return header
      })

      headers.push({ text: '', value: 'actions', width: '200px' })
      return headers
    }
  },
  data () {
    return {
      items: [],
      removeDialogActive: false,
      removeVm: null
    }
  },
  methods: {
    async getItems () {
      const { data: items } = await Service.get('/Dictionary/GetFreightTypes')
      this.items = this.mapItems(items)
    },
    async saveItem (item) {
      const languages = this.languages.map(lang => lang.nameISO)
      const translatedValues = []

      for (const prop in item) {
        if (languages.some(lang => lang === prop)) {
          translatedValues.push({
            language: prop,
            name: item[prop]
          })
        }
      }

      if (!item.id) {
        await Service.post('/Dictionary/AddFreightType', {
          translatedValues: translatedValues
        })
        this.getItems()
      }
    },
    isSaveButtonDisabled (item) {
      if (!item.dirtField) {
        return true
      }

      for (const prop in item) {
        if (prop !== 'id') {
          if (item[prop].length === 0) {
            return true
          }
        }
      }

      return false
    },
    addNew () {
      if (!this.items.some(item => !item.id)) {
        const item = {}
        this.languages.forEach(lang => {
          item[lang.nameISO] = ''
        })
        this.items.push(item)
      }
    },
    mapItems (items) {
      return items.filter(item => !item.deleted).map(item => {
        const val = {
          id: item.id,
          dirtField: false
        }

        item.translatedValues.forEach(value => {
          val[value.language] = value.name
        })
        return val
      })
    },
    dirtBlur (item) {
      item.dirtField = true
    },
    openRemoveDialog (item) {
      this.removeVm = item
      if (item.id) {
        this.removeDialogActive = true
      } else {
        this.removeItem()
      }
    },
    closeRemoveDialog () {
      this.removeDialogActive = false
      this.removeVm = null
    }
  },
  mounted () {
    this.getItems()
  }
}
</script>
